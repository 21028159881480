.custom-button {
    padding: 10px 20px;
    color: #000000;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-family: 'Rubik Doodle Shadow', system-ui;
    font-size: clamp(15px, 4vw, 20px);
    font-weight: bold;
    border-radius: 5px;
    background-color: #f88787;
    margin: 50px auto;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 25px;
    /* font-size: 1.2vw !important; */
    font-size: clamp(2vw, 2.2vw, 2.2vw);
    cursor: pointer;


  }
  
  .custom-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #3498db 0%, #ffffff 120%);
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s ease;
  }
  
  .custom-button:active::after {
    opacity: 1;
  }
  