.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width : 100%;
  height: 100vh;
  background: rgb(222, 188, 20);
  transform-origin: bottom;
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width : 100%;
  height: 100vh;
  background: rgb(222, 188, 20);
  transform-origin: top;
}

.main-audio {
  font-size: clamp(25px, 3vw, 40px);
  position: fixed;
  left: auto;
  top: 50px;
  right:10%;
  cursor: pointer;
  z-index: 10000;
}

.ai-img {
  width: 80px;
  position: fixed;
  top: 60%;
  right: 1%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2000;

}