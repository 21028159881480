.chat-container {
    display: flex;
    height: 440px;
    width: 440px;
    margin: 0 auto;
    margin-right: 15px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    flex-wrap: wrap;
    overflow-y: scroll;
    position: fixed;
    bottom: 1%;
    right: 0%;
    z-index: 2000;
    text-align: left;
    font-size: clamp(16px, 2vw, 20px);
    border: 1px solid #ffcc00 ;
    border-radius: 0 0 5px 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    .chat-nav {
      display: flex;
      justify-content: space-between;
      background-color: #ffcc00;
      width: 440px;
      position: fixed;
      bottom: 440px;
      right:0%;
      margin-right: 15px;
      z-index: 10;
      padding: 10px;
      border-radius: 5px 5px 0 0;
      .icon {
        font-size: 25px;
        color: white;
        cursor: pointer;
      }
    }
    .chat-input-form {
      position: fixed;
      bottom: 2%;
      display: flex;
      border: 1px solid #ffcc00;
      border-radius: 4px;
      }
      
    .input-field {
      flex: 1;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      height: 45px;
      border: none;
      width: 350px;
      z-index: 10000;
    }
      
   

    .send-button {
      background-color: transparent;
      color: rgb(0, 0, 0);
      border: none;
      border-radius: 4px;
      width: 40px;
      cursor: pointer;
      font-weight: 600;
      padding-left: 10px;
  
    }
      
    .send-button:hover {
      background-color: #ffcc00;
    }
  }
  
  .chat-messages {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    width: 100%;
  }
  
  .message {
    background-color: #e7e5d2;
    border-radius: 8px;
    padding: 8px 12px;
    margin: 4px;
    max-width: 70%;
  }
  
  .user {
    align-self: flex-end;
    background-color: #fed801;
  }
  
  .ai {
    align-self: flex-start;
  }
  
  
  
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  

  
  .loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
  }
  
  .bounce {
    
    display: inline-block;
    margin: 0 2px;
    
  }
  .b1 {
    animation: bounce 1s infinite;
    animation-delay: 0.2s; 

  }
  .b2 {
    animation: bounce 1s infinite;
    animation-delay: 0.3s; 

  }
  .b3 {
    animation: bounce 1s infinite;
    animation-delay: 0.4s; 

  }
@media only screen and (max-width: 768px) {
  .chat-container {
   
    height: 380px;
    width: 340px;
    .input-field {
     
      height: 40px;
      width: 280px;
      
    }
      
   

    .send-button {
    
      width: 35px;
      font-weight: 600;
    
    }
    .chat-nav{
      width: 340px;
      bottom: 380px;
      .icon {
        font-size: 20px;
      }
    }
  
  }
}