@font-face {
    font-family: 'bigGameCustom';
    src: url('../Welcome/fonts/GamepauseddemoRegular-RpmY6.otf') format('opentype');
    
}

@font-face {
    font-family: 'smallTypewriter';
    src: url('../Welcome/fonts/JMH Typewriter-Black.otf') format('opentype');
}

.PageBackground {
    height: 100vh;
    width: 100vw;
    min-width: 360px;
    background-image: url("../Welcome/bgImgWelc.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
    perspective-origin: 50% 50%;
    animation: flow-effect 100s ease infinite;
}

@keyframes flow-effect {
    
    100% {
        background-position: 0px -400%;
        
      }
}

.tax-dude{
    animation: tax-rotation 2s ease infinite;
}

@keyframes tax-rotation{
    0% {
        transform: rotate(-15deg);
    }
    50%{
        transform: rotate(15deg);
    }
    100%{
        transform: rotate(-15deg);
    }
}

.text-zoom{
    animation: zooming 2s ease infinite;
}

@keyframes zooming{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.25);
    }
    100%{
        transform: scale(1);
    }
}
