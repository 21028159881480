@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pattern-background {
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(8) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0, 0%, 93%, 1)'/><path d='M 10,-2.55e-7 V 20 Z M -1.1677362e-8,10 H 20 Z'  stroke-width='0.5' stroke='hsla(51, 21%, 87%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
  width: 100vw;
  height: 100vh;
  background-size: cover;
}

@font-face {
  font-family: "Game Pause";
  src: local("YourFontName"),
    url("./assets/fonts/GamepauseddemoRegular-RpmY6.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "smallTypewriter";
  src: url("./components/Welcome/fonts/JMH Typewriter-Black.otf")
    format("opentype");
}

.level-title {
  font-family: "Game Pause", sans-serif;
  color: rgb(246, 218, 116);
}

.level2-title {
  font-family: "Game Pause", sans-serif;
  color: #fb9788;
}

.bordered-text {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.form-font {
  font-family: "smallTypewriter", sans-serif;
  color: black;
}

.custom-shadow {
  box-shadow: 15px 15px 0 rgb(212, 215, 211);
}
